<template>
  <v-container
    class="px-sm-10 py-sm-10"
    fluid
    style="
      min-height: 100vh;
      background: #E6E6E6;
    "
  >
    <v-row>
      <v-col>
        <span
          style="cursor: pointer"
          @click="navBack"
        >
          <v-icon
            style="
            font-size: 16px;
            color: #7A1878;
          "
          >
            fas fa-chevron-left
          </v-icon>
          <span
            class="ml-1"
            style="color: #7A1878"
          >
            Kembali
          </span>
        </span>
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col style="font-size: 26px">
        Detail Perusahaan Penyewa
      </v-col>
    </v-row>

    <v-row class="mt-n5">
      <v-col>
        <!-- Informasi Perusahaan -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Informasi Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Merek
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.name || '-' }}
              <span v-if="detail.verified">
                <v-icon color="#E6E6E6">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama Legal
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.name_legal || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Status
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.status_string || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Telepon
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.phone || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Alamat
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.address_string || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Trapoin
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.wallet_saldo || 0 }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Total Transaksi
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.trx_total || 0 }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="font-common-black-bold">
              Dokumen Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              NPWP
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              <template v-if="detail.documents.length > 0">
                <span>
                  {{ detail.documents[0].type }}
                </span>

                <span
                  class="ml-1"
                  style="cursor: pointer"
                  @click="downloadAttachment(detail.documents[0].file_path_view_only)"
                >
                  <v-icon>
                    fas fa-eye
                  </v-icon>
                </span>
              </template>
            </v-col>
          </v-row>
        </v-card>

        <!-- Pemilik Perusahaan -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Pemilik Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Nama
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.owner.name || '-' }}
              <span v-if="detail.owner.verified">
                <v-icon color="#E6E6E6">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Telepon
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.owner.phone || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Email
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.owner.email || '-' }}
              <span v-if="detail.owner.email_verified">
                <v-icon color="#E6E6E6">
                  mdi-checkbox-marked-circle
                </v-icon>
              </span>
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Alamat
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ detail.owner.address_string || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Newsletter
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              {{ newsLetter || '-' }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>

          <v-row>
            <v-col class="font-common-black-bold">
              Dokumen Pemilik Perusahaan
            </v-col>
          </v-row>

          <v-row>
            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Foto KTP
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              <template v-if="detail.owner.ktp !== null">
                <span>
                  Foto_KTP
                </span>

                <span
                  class="ml-1"
                  style="cursor: pointer"
                  @click="downloadAttachment(detail.owner.ktp.data.file_path)"
                >
                  <v-icon>
                    fas fa-eye
                  </v-icon>
                </span>
              </template>
            </v-col>

            <v-col
              class="font-common"
              cols="3"
              sm="3"
            >
              Foto Selfie KTP
            </v-col>

            <v-col
              class="font-common-bold"
              cols="9"
              sm="9"
            >
              <template v-if="detail.owner.ktp_selfie !== null">
                <span>
                  Selfie_KTP
                </span>

                <span
                  class="ml-1"
                  style="cursor: pointer"
                  @click="downloadAttachment(detail.owner.ktp_selfie.data.file_path)"
                >
                  <v-icon>
                    fas fa-eye
                  </v-icon>
                </span>
              </template>
            </v-col>
          </v-row>
        </v-card>

        <!-- Riwayat Transaksi Perusahaan Penyewa -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Riwayat Transaksi Perusahaan Penyewa
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerRiwayatPenyewa"
                :items="riwayatPenyewa"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.code="{ item }">
                  <div
                    class="font-common"
                    style="
                      color: #7A1878;
                      cursor: pointer;
                    "
                    @click="toOrderDetail(item.hash)"
                  >
                    {{ item.code }}
                  </div>
                </template>

                <template v-slot:item.total_amount="{ item }">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span>
                      Rp
                    </span>

                    <span>
                      {{ item.total_amount | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageRiwayatPenyewa"
                      :length="pageCountRiwayatPenyewa"
                      color="purple"
                      :total-visible="5"
                      @input="onChangePageRiwayatPenyewa"
                    />
                    <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageRiwayatPenyewa }} of {{ pageCountRiwayatPenyewa || 1 }}
                    </span>
                  </div>
                  <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountRiwayatPenyewa + ' dari ' + itemTotalRiwayatPenyewa + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- Mutasi Trapoin -->
        <v-card class="px-4 py-4">
          <v-row>
            <v-col class="font-common-black-bold">
              Mutasi Trapoin
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table
                :headers="headerMutasiTrapoin"
                :items="mutasiTrapoin"
                :items-per-page="25"
                class="elevation-0"
                mobile-breakpoint="100"
                hide-default-footer
              >
                <template v-slot:item.nominal="{ item }">
                  <div
                    style="
                      display: flex;
                      justify-content: space-between;
                    "
                  >
                    <span :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'">
                      Rp
                    </span>

                    <span :style="item.nominal < 0 ? 'color: #EA3E3A' : 'color: #5DB025'">
                      {{ item.nominal | dotThousandSeparator }}
                    </span>
                  </div>
                </template>

                <template v-slot:item.description="{ item }">
                  <div class="font-common">
                    {{ item.description || '-' }}
                  </div>
                </template>

                <template v-slot:footer>
                  <div class="text-center my-5 py-3">
                    <v-pagination
                      v-model="pageMutasiTrapoin"
                      :length="pageCountMutasiTrapoin"
                      color="purple"
                      @input="onChangePageMutasiTrapoin"
                    />
                    <span style="font-size: 12px; font-style: italic;">
                      Page {{ pageMutasiTrapoin }} of {{ pageCountMutasiTrapoin || 1 }}
                    </span>
                  </div>
                  <v-row class="mt-n16">
                    <v-col class="text-right pr-8">
                      <span style="font-size: 12px; font-style: italic;">
                        {{ itemCountMutasiTrapoin + ' dari ' + itemTotalMutasiTrapoin + ' ditampilkan' }}
                      </span>
                    </v-col>
                  </v-row>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-card>

        <!-- BLOKIR PENYEWA PERUSAHAAN -->
        <template v-if="detail.status === 1 && customerCompanyEdit">
          <v-row class="my-4">
            <v-col class="text-right">
              <v-btn
                style="
                  background: #EA3E3A;
                  width: 162px;
                  height: 46px;
                "
                @click="dialogBlokirPenyewa = true"
              >
                <span
                  class="text-capitalize font-common-white-bold"
                >
                  Blokir Perusahaan
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>

        <!-- BUKA BLOKIR PENYEWA -->
        <template v-if="detail.status === 2 && customerCompanyEdit">
          <v-row class="my-4">
            <v-col class="text-right">
              <v-btn
                class="elevation-0"
                style="
                  background: #FAFAFA;
                  width: 180px;
                  height: 46px;
                "
                @click="dialogUnBlokirPenyewa = true"
              >
                <span
                  class="text-capitalize font-common-bold"
                >
                  Buka Blokir Perusahaan
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-col>
    </v-row>

    <!-- DIALOG BLOKIR PENYEWA -->
    <v-dialog
      v-model="dialogBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin memblokir perusahaan ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Masukkan Password"
              color="purple"
              type="password"
              outlined
              dense
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(2)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- DIALOG UN-BLOKIR PENYEWA -->
    <v-dialog
      v-model="dialogUnBlokirPenyewa"
      max-width="400"
    >
      <v-card class="px-10 py-8">
        <v-row>
          <v-col class="font-common-bold text-center">
            Apakah kamu yakin buka blokir perusahaan ini?
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <v-text-field
              v-model="password"
              label="Masukkan Password"
              color="purple"
              type="password"
              outlined
              dense
              auto-complete="off"
            />
          </v-col>
        </v-row>

        <v-row class="mt-n5">
          <v-col class="text-center">
            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 128px;
                height: 46px;
                background: #7A1878;
              "
              @click="blokirPenyewa(1)"
            >
              Ya, Lanjutkan
            </v-btn>

            <v-btn
              class="text-capitalize elevation-0"
              style="
                width: 70px;
                height: 46px;
                background: #F2F2F2;
              "
              @click="dialogUnBlokirPenyewa = false"
            >
              <span class="font-common-bold">
                Batal
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>

    <!-- IMAGE PREVIEW -->
    <div
      id="myModal"
      class="modal"
    >
      <span class="close">&times;</span>
      <img
        id="img01"
        class="modal-content"
      >
    </div>
  </v-container>
</template>

<script>
  import axios from 'axios'
  import { mapState } from 'vuex'
  axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
  axios.defaults.headers.post['x-api-key'] = localStorage.getItem('token')

  export default {
    data: () => ({
      detail: {
        hash: '',
        status: 1,
        status_string: 'Aktif',
        name: '',
        name_legal: '',
        director_name: '',
        documents: [],
        phone: '',
        address: '',
        address_string: '',
        verified: 0,
        wallet_saldo: null,
        trx_total: 0,
        owner: {
          hash: '',
          verified: 1,
          name: '',
          phone: null,
          email: '',
          email_verified: 1,
          address: '',
          address_string: '',
          newsletter: 1,
          ktp: null,
          ktp_selfie: null,
        },
      },

      // Riwayat Penyewa:
      headerRiwayatPenyewa: [
        { text: 'Kode Pesanan', align: 'start', value: 'code', sortable: false },
        { text: 'Tanggal Pesanan', value: 'created_time' },
        { text: 'Status', value: 'status_string', sortable: false },
        { text: 'Nominal', value: 'total_amount' },
      ],
      riwayatPenyewa: [],

      pageRiwayatPenyewa: 1,
      pageCountRiwayatPenyewa: 0,
      itemCountRiwayatPenyewa: 0,
      itemTotalRiwayatPenyewa: 0,

      // Mutasi Trapoin:
      headerMutasiTrapoin: [
        { text: 'Jenis Transaksi', align: 'start', value: 'type', sortable: false },
        { text: 'Tanggal Transaksi', value: 'tanggal', sortable: false },
        { text: 'Nominal', value: 'nominal', sortable: false },
        { text: 'Keterangan', value: 'description', sortable: false },
      ],
      mutasiTrapoin: [],

      pageMutasiTrapoin: 1,
      pageCountMutasiTrapoin: 0,
      itemCountMutasiTrapoin: 0,
      itemTotalMutasiTrapoin: 0,

      dialogBlokirPenyewa: false,
      dialogUnBlokirPenyewa: false,
      password: '',

      // Permission:
      userMenus: localStorage.getItem('userMenus').split(','),
    }),

    computed: {
      ...mapState({
        itemsPerPage: state => state.setting.itemsPerPage,
      }),

      newsLetter () {
        let r = ''
        this.detail.newsletter_subscribe ? r = 'Langganan' : r = 'Tidak Berlangganan'

        return r
      },

      customerCompanyEdit () {
        let v = false
        if (this.userMenus.includes('CUSTOMER_COMPANY_EDIT')) v = true

        return v
      },
    },

    watch: {
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        const vm = this

        const requestBody = {
          cust_com_hash: this.$route.params.id,
        }

        axios.post('/v2/t/customer/company/detail', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.detail = res.data.data.detail

            this.getRiwayatPenyewa()
            this.getMutasiTrapoin()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getRiwayatPenyewa (page) {
        const vm = this

        const requestBody = {
          page: page,
          cust_hash: this.detail.owner.hash,
        }

        axios.post('/v2/t/customer/detail/invoice', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.riwayatPenyewa = res.data.data.list
            // pagination:
            vm.pageCountRiwayatPenyewa = res.data.data.pagination.page_total
            vm.itemCountRiwayatPenyewa = res.data.data.pagination.item_count
            vm.itemTotalRiwayatPenyewa = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      getMutasiTrapoin (page) {
        const vm = this

        const requestBody = {
          page: page,
          cust_hash: this.detail.owner.hash,
        }

        axios.post('/v2/t/customer/detail/trapoin', requestBody).then((res) => {
          if (res.data.status === 200) {
            vm.mutasiTrapoin = res.data.data.list
            // pagination:
            vm.pageCountMutasiTrapoin = res.data.data.pagination.page_total
            vm.itemCountMutasiTrapoin = res.data.data.pagination.item_count
            vm.itemTotalMutasiTrapoin = res.data.data.pagination.item_count_total
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      blokirPenyewa (status) {
        const requestBody = {
          cust_comp_hash: this.$route.params.id,
          pwd: this.password,
          new_status: status,
        }

        axios.post('/v1/t/customer/company/mod/status', requestBody).then((res) => {
          if (res.data.status === 200) {
            this.dialogBlokirPenyewa = false
            this.dialogUnBlokirPenyewa = false
            this.password = ''
            this.$toast.success('Berhasil mengubah status perusahaan')
            this.initialize()
          }
        })
          .catch((e) => {
            if (typeof (e.response.data.error) === 'object') {
              this.$toast.error(Object.values(e.response.data.error)[0])
            } else {
              this.$toast.error(e.response.data.error)
            }
          })
      },

      toOrderDetail (hash) {
        this.$router.push({ name: 'PesananDetail', params: { id: hash } })
      },

      onChangePageMutasiTrapoin (e) {
        this.getMutasiTrapoin(e)
      },

      onChangePageRiwayatPenyewa (e) {
        this.getRiwayatPenyewa(e)
      },

      navBack () {
        this.$router.push({ name: 'Penyewa' })
      },

      downloadAttachment (data) {
        // const type = data.type.toUpperCase()
        // if (type === 'JPG' || type === 'JPEG' || type === 'PNG' || type === 'IMAGE') {
        //   const modal = document.getElementById('myModal')
        //   const modalImg = document.getElementById('img01')
        //   modal.style.display = 'block'
        //   if (data.url) modalImg.src = data.url
        //   if (data.path) modalImg.src = data.path
        //   const span = document.getElementsByClassName('close')[0]
        //   span.onclick = function () {
        //     modal.style.display = 'none'
        //   }
        // } else if (type === 'PDF') {
        //   window.open(data.url, '_blank')
        // } else {
        //   window.open(data.url_download, '_blank')
        // }
        const modal = document.getElementById('myModal')
        const modalImg = document.getElementById('img01')
        modal.style.display = 'block'
        modalImg.src = data
        const span = document.getElementsByClassName('close')[0]
        span.onclick = function () {
          modal.style.display = 'none'
        }
      },
    },
  }
</script>

<style scoped>
.font-common {
  font-family: Lato;
  font-size: 16px;
  color: #808080;
}

.font-common-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #808080;
}

.font-common-black {
  font-family: Lato;
  font-size: 16px;
  color: #333333;
}

.font-common-black-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #333333;
}

.font-common-white {
  font-family: Lato;
  font-size: 16px;
  color: #FFFFFF;
}

.font-common-white-bold {
  font-family: Lato;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
}

/* MODAL IMAGE  */
#myImg {
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
#myImg:hover {
  opacity: 0.7;
}
.modal {
  z-index: 10;
  display: none;
  position: fixed;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
}
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}
.modal-content,
#caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}
@-webkit-keyframes zoom {
  from {
    -webkit-transform: scale(0);
  }
  to {
    -webkit-transform: scale(1);
  }
}
@keyframes zoom {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.close {
  position: absolute;
  top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}
.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}
@media only screen and (max-width: 700px) {
  .modal-content {
    width: 100%;
  }
}
/* END OF MODAL IMAGE */
</style>
